import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SecondaryPageHeader from '../components/SecondaryPageHeader'
import IssuesSlider from '../components/IssuesSlider'
import SEO from "../components/seo"

const Issues = () => {

  const data = useStaticQuery(graphql`
  query {
    heroImage: file(relativePath: {eq: "issues_bg.jpg" }) {
      childImageSharp{
        fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <Layout>
      <SEO title="Issues" />
      <SecondaryPageHeader heading={"Issues"} image={data.heroImage.childImageSharp.fluid} />
      <IssuesSlider />
    </Layout>
  )
}

export default Issues

